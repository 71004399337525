import { ReactNode, useState, useEffect, useCallback } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  createStyles,
  Theme,
  useTheme,
  withStyles,
  TextField,
  InputLabel,
  Box,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import HomeIcon from '../icons/HomeIcon';
import CourseIcon from '../icons/CourseIcon';
import ForumIcon from '../icons/ForumIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import EditIcon from '@material-ui/icons/Edit';
import ShowChartIcon from '@material-ui/icons/ShowChartOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import { Link, useLocation, matchPath, useHistory } from 'react-router-dom';
import * as paths from '../../routes/paths';
import { getUserAreaPageUrl, homeUrl } from '../../routes/paths';
import { userSelector } from '../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  isCourseAdmin,
  isForumAdmin,
  isAdmin,
  ActorsUsersDetails,
  Group,
} from '../../types';
import Message from '../translation/Message';
import {
  instanceModulesSelector,
  activeSubModulesSelector,
  groupLimitSelector,
} from '../../store/instanceSlice';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import {
  relatedUsersBasedOnActorSelector,
  fetchRelatedUsersBasedOnActors,
  actorsSelector,
  fetchActors,
  actorsUsersDetailsListSelector,
  fetchActorsUsersDetailsList,
} from '../../store/actorsSlice';
import {
  checkUserPermissions,
  groupAndCustomerOnlySelector,
  setTabs,
  setUserArea,
  userAreaSelector,
} from '../../store/userAreaSlice';
import {
  courseTypesSelector,
  fetchCourseTypes as fetchCourseTypesEdit,
} from '../../store/editCourseSlice';
import MaterialIcon from '../icons/MaterialIcon';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import theme from '../../theme';
import {
  createGroup,
  currentGroupSelector,
  fetchGroups,
  groupsSelector,
  setCurrentGroup,
} from '../../store/groupSlice';
import InfoDialog from '../InfoDialog';
import { SchoolOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navListItem: {
      position: 'relative',
    },
    navMarker: {
      position: 'absolute',
      right: 5,
      height: 34,
      width: 8,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 4,
    },
    usersBasedOnActorLabel: {
      fontSize: '14px',
      paddingBottom: '2px',
    },
    adminSection: {
      padding: '1em 0em',
      marginTop: '1em',
      backgroundColor: theme.palette.grey[300],
    },
    adminLabel: {
      paddingBottom: '0.5em',
      paddingLeft: '1em',
    },
    accordionNavItem: {
      backgroundColor: '#d6d6d6',
    },
  })
);

const Accordion = withStyles({
  root: {
    minHeight: 'unset',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    marginBottom: -1,
    minHeight: 'unset',
    backgroundColor: theme.palette.grey[300],
    '&$expanded': {
      minHeight: 'unset',
    },
    '& > *:last-child': { marginRight: 0 },
    '&:hover': {
      backgroundColor: '#d6d6d6',
    },
  },
  content: {
    margin: '0px 0',
    '&$expanded': {
      margin: '0px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: 0,
    display: 'block',
  },
})(MuiAccordionDetails);

type DrawerListProps = {
  onDrawerToggle: () => void;
  onItemClick?: () => void;
};
const DrawerList = ({ onDrawerToggle, onItemClick}: DrawerListProps) => {
  const [expandedEditContent, setExpandedEditContent] =
    useState<boolean>(false);
  const [expandedSettings, setExpandedSettings] = useState<boolean>(false);
  const [expandedStatistics, setExpandedStatistics] = useState<boolean>(false);

  const [hasRelatedUsers, setHasRelatedUsers] = useState<boolean>(false);
  const [selectedRelatedUser, setSelectedRelatedUser] =
    useState<ActorsUsersDetails | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [groupOptions, setGroupOptions] = useState<Group[]>([]);
  const [isGroupLimitModalOpen, setIsGroupLimitModalOpen] =
    useState<boolean>(false);

  const [canAssignActorIds, setCanAssignActorIds] = useState<string[]>([]);
  const groupAndCustomerOnly = useSelector(groupAndCustomerOnlySelector);

  const user = useSelector(userSelector);
  const groups = useSelector(groupsSelector);
  const groupLimit = useSelector(groupLimitSelector);
  const relatedUsersBasedOnActor = useSelector(
    relatedUsersBasedOnActorSelector
  );
  const actors = useSelector(actorsSelector);
  const actorsUsersDetails = useSelector(actorsUsersDetailsListSelector);

  const activeModules = useSelector(instanceModulesSelector);
  const activeSubModules = useSelector(activeSubModulesSelector);
  const courseTypes = useSelector(courseTypesSelector);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const matchPage = (path: string, exact = false) => {
    return Boolean(matchPath(location.pathname, { path, exact }));
  };

  const getCourseTypeIcon = (iconName: string) => {
    if (iconName === 'Default') {
      return <CourseIcon />;
    } else {
      return (
        <MaterialIcon fontSize="small" iconName={iconName.split('Icon')[0]} />
      );
    }
  };

  const getSortedRelatedUsers = () => {
    if (!relatedUsersBasedOnActor) return [];
    return relatedUsersBasedOnActor
      .slice()
      .sort((a: ActorsUsersDetails, b: ActorsUsersDetails) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
  };

  const getGroupOptionsWithNewGroup = useCallback(() => {
    if (!groups) return [];
    return [
      { id: 'new-group-button', name: getMessage('nav.newGroup') },
    ].concat(groups);
  }, [groups]);

  useEffect(() => {
    if (groupAndCustomerOnly === null && user) {
      dispatch(checkUserPermissions(user.userInstanceId));
    }
  }, [dispatch, groupAndCustomerOnly, user]);

  useEffect(() => {
    if (!user) return;
    if (
      !groups &&
      (activeSubModules?.Course.includes('GroupCourse') ||
        activeSubModules?.Course.includes('GroupVideos') ||
        activeSubModules?.Forum.includes('GroupForum'))
    ) {
      dispatch(fetchGroups());
    }
    if (groups) {
      setGroupOptions(isAdmin(user) ? getGroupOptionsWithNewGroup() : groups);
    }
  }, [
    activeSubModules?.Course,
    activeSubModules?.Forum,
    dispatch,
    getGroupOptionsWithNewGroup,
    groups,
    user,
  ]);

  useEffect(() => {
    if (!actors) {
      dispatch(fetchActors());
    }

    if (actors && actors.length > 0) {
      const actorIdsWithRooms = actors
        .filter((a) => a.hasPrivateRoom)
        .map((a) => a.id);
      if (actorIdsWithRooms.length === 0) {
        setHasRelatedUsers(false);
      } else {
        dispatch(fetchRelatedUsersBasedOnActors(actorIdsWithRooms));
        setHasRelatedUsers(true);
      }
    }
  }, [dispatch, actors]);

  useEffect(() => {
    if (user && actors && !actorsUsersDetails) {
      dispatch(fetchActorsUsersDetailsList());
    }

    if (actorsUsersDetails && actorsUsersDetails.length > 0 && user && actors) {
      const currentActorUser = actorsUsersDetails.find(
        (au) => au.id === user.id || au.id === user.userInstanceId
      );
      if (!currentActorUser) return;

      const currentActorIds = currentActorUser.actors.map((a) => a.id);
      const currentCanAssign = actors
        .filter((a) => currentActorIds.includes(a.id))
        .map((a) => a.canAssignActorIds)
        .flat(1);
      setCanAssignActorIds(currentCanAssign);
    }
  }, [actors, actorsUsersDetails, dispatch, user]);

  useEffect(() => {
    if (
      !courseTypes &&
      activeModules &&
      activeModules?.includes('Course') &&
      activeSubModules?.Course?.includes('PublicCourse')
    ) {
      dispatch(fetchCourseTypesEdit());
    }
  }, [dispatch, courseTypes, activeModules, activeSubModules]);
  const regularNavItems: NavItemProps[] = [
    {
      text: getMessage('nav.home'),
      icon: <HomeIcon />,
      selected: matchPage(paths.homeUrl, true) || matchPage('', true),
      url: paths.homeUrl,
      moduleActive: true,
    },
    {
      text: getMessage('nav.forum'),
      icon: <ForumIcon />,
      selected: matchPage(paths.forumUrl),
      url: paths.forumUrl,
      moduleActive:
        activeModules?.includes('Forum') &&
        activeSubModules?.Forum?.includes('PublicForum'),
    },
  ];

  if (courseTypes && courseTypes.length > 0) {
    const courseTypesNavItems = courseTypes.map((type) => {
      return {
        text: type.name,
        icon: getCourseTypeIcon(type.icon),
        selected: matchPage(paths.getCourseTypeUrl(type.name)),
        url: paths.getCourseTypeUrl(type.name),
        color: type.color,
        moduleActive:
          activeModules?.includes('Course') &&
          activeSubModules?.Course?.includes('PublicCourse') &&
          type.isActive,
      };
    }) as NavItemProps[];
    regularNavItems.splice(1, 0, ...courseTypesNavItems);
  }

  const courseAdminNavItems: NavItemProps[] = [
    {
      text: <Message id="nav.editCourses" />,
      icon: <EditIcon />,
      selected:
        matchPage(paths.courseAdminDashboardUrl, true) ||
        matchPage(paths.editCourseUrl),
      url: paths.courseAdminDashboardUrl,
    },
    {
      text: <Message id="nav.editProviders" />,
      icon: <EditIcon />,
      selected:
        matchPage(paths.editProvidersDashboardUrl, true) ||
        matchPage(paths.editProviderUrl),
      url: paths.editProvidersDashboardUrl,
    },
  ];

  const forumAdminNavItems: NavItemProps[] = [
    {
      text: <Message id="nav.editForum" />,
      icon: <EditIcon />,
      selected: matchPage(paths.forumAdminPage),
      url: paths.forumAdminPage,
    },
  ];

  const homeAdminNavItems: NavItemProps[] = [
    {
      text: <Message id="nav.editHome" />,
      icon: <EditIcon />,
      selected: matchPage(paths.homeAdminPageUrl),
      url: paths.homeAdminPageUrl,
    },
  ];

  const adminSettingsNavItems: NavItemProps[] = [
    {
      text: <Message id="nav.siteSettings" />,
      icon: <EditIcon />,
      selected: matchPage(paths.siteSettingsPageUrl),
      url: paths.siteSettingsPageUrl,
    },

    {
      text: <Message id="nav.contentSettings" />,
      icon: <EditIcon />,
      selected: matchPage(paths.contentSetingsPageUrl),
      url: paths.contentSetingsPageUrl,
    },
  ];

  const actorAdminNavItem: NavItemProps = {
    text: <Message id="nav.handleActors" />,
    icon: <SupervisorAccountOutlinedIcon />,
    selected: matchPage(paths.actorsAdminPageUrl),
    url: paths.actorsAdminPageUrl,
  };

  const courseAdminNavItem: NavItemProps = {
    text: <Message id="nav.handleUserCourses" />,
    icon: <SchoolOutlined />,
    selected: matchPage(paths.allCoursesPageUrl),
    url: paths.allCoursesPageUrl,
  };

  const usersAdminNavItems: NavItemProps[] = [
    {
      text: <Message id="nav.handleUsers" />,
      icon: <SupervisorAccountOutlinedIcon />,
      selected: matchPage(paths.handleUsersUrl),
      url: paths.handleUsersUrl,
    },
    actorAdminNavItem,
  ];

  const statisticsNavItems: NavItemProps[] = [
    {
      text: !!actors ? (
        <Message id="nav.statistics.overview" />
      ) : (
        <Message id="nav.statistics.statistics" />
      ),
      icon: <ShowChartIcon />,
      selected: matchPage(paths.statisticsOverviewPageUrl),
      url: paths.statisticsOverviewPageUrl,
    },
    {
      text: <Message id="nav.statistics.users" />,
      icon: <ShowChartIcon />,
      selected: matchPage(paths.statisticsUsersPageUrl),
      url: paths.statisticsUsersPageUrl,
      moduleActive: !!actors,
    },
  ];
  return (
    <List>
      {groupAndCustomerOnly !== null &&
        !groupAndCustomerOnly &&
        regularNavItems.map(
          (item, index) =>
            (item.moduleActive || !item.hasOwnProperty('moduleActive')) && (
              <NavListItem
                key={index}
                text={item.text}
                icon={item.icon}
                selected={item.selected}
                url={item.url}
                color={item.color}
                onClick={isMobile ? onItemClick : undefined}
              />
            )
        )}
      {groupAndCustomerOnly !== null &&
        hasRelatedUsers &&
        relatedUsersBasedOnActor &&
        relatedUsersBasedOnActor.length > 0 && (
          <Autocomplete
            key={'actor-autocomplete'}
            options={getSortedRelatedUsers()}
            getOptionLabel={(option) => option.name}
            id="relatedUsersAutomcomplete"
            style={{ padding: '16px' }}
            value={selectedRelatedUser}
            onBlur={() => setSelectedRelatedUser(null)}
            onChange={(_e, value, reason) => {
              dispatch(setCurrentGroup(null));
              dispatch(setTabs(null));
              if (reason === 'select-option' && value) {
                setSelectedRelatedUser(value);
                dispatch(setUserArea(value));
                history.push(getUserAreaPageUrl(value.id));
              } else {
                setSelectedRelatedUser(null);
                dispatch(setUserArea(null));
                history.push(homeUrl);
              }
              if (isMobile) {
                onDrawerToggle();
              }
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <Box mt={2}>
                <InputLabel
                  id="relatedUsersAutomcomplete-label"
                  className={classes.usersBasedOnActorLabel}
                >
                  <Typography variant="overline">
                    <Message id="nav.usersListLabel" />
                  </Typography>
                </InputLabel>
                <TextField
                  {...params}
                  placeholder={getMessage('nav.usersListPlaceholder')}
                />
              </Box>
            )}
          />
        )}
      {user && groups && (groups.length > 0 || isAdmin(user)) && (
        <Autocomplete
          key={'group-autocomplete'}
          options={groupOptions}
          getOptionLabel={(option) => option.name}
          id="groupsAutomcomplete"
          style={{ padding: '16px' }}
          value={selectedGroup}
          onBlur={() => setSelectedGroup(null)}
          onInput={(event) => {
            const value = (event.target as HTMLTextAreaElement).value;
            if (value || !isAdmin(user)) {
              setGroupOptions(groups);
            } else {
              setGroupOptions(getGroupOptionsWithNewGroup());
            }
          }}
          onChange={(_e, value, reason) => {
            dispatch(setUserArea(null));
            dispatch(setTabs(null));
            if (reason === 'select-option' && value) {
              if (value.id === 'new-group-button') {
                if (groupLimit && groupLimit <= groups.length) {
                  setIsGroupLimitModalOpen(true);
                  return;
                }
                setSelectedGroup(null);
                dispatch(createGroup(user.userInstanceId));
                history.push(paths.newPrivateGroupUrl);
              } else {
                setSelectedGroup(value);
                dispatch(setCurrentGroup(value));
                history.push(getUserAreaPageUrl(value.id));
              }
            } else {
              setSelectedGroup(null);
              dispatch(setCurrentGroup(null));
              history.push(homeUrl);
            }
            if (isMobile) {
              onDrawerToggle();
            }
          }}
          renderInput={(params: AutocompleteRenderInputParams) => {
            return (
              <Box mt={2}>
                <InputLabel
                  id="groupsAutomcomplete-label"
                  className={classes.usersBasedOnActorLabel}
                >
                  <Typography variant="overline">
                    <Message id="nav.groupsLabel" />
                  </Typography>
                </InputLabel>
                <TextField
                  {...params}
                  placeholder={getMessage('nav.groupsPlaceholder')}
                />
              </Box>
            );
          }}
        />
      )}
      <InfoDialog
        open={isGroupLimitModalOpen}
        onClose={() => setIsGroupLimitModalOpen(false)}
        action={() => setIsGroupLimitModalOpen(false)}
        title={getMessage('handleActors.allowedGroupLimitReached3')}
        noCancel
      >
        <Message id="handleActors.allowedGroupLimitReached1" />
        {groupLimit}
        <Message id="handleActors.allowedGroupLimitReached2" />
      </InfoDialog>
      {user && (isCourseAdmin(user) || isForumAdmin(user) || isAdmin(user)) && (
        <Box className={classes.adminSection}>
          <InputLabel id="admin-items-label" className={classes.adminLabel}>
            <Typography variant="overline">
              <Message id="nav.adminLabel" />
            </Typography>
          </InputLabel>
          <Accordion
            expanded={expandedEditContent}
            onChange={() => {
              if (!expandedEditContent) {
                setExpandedSettings(false);
                setExpandedStatistics(false);
              }
              setExpandedEditContent(!expandedEditContent);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="edit-content"
              id="edit-content"
            >
              <ListItem>
                <ListItemIcon>{<SettingsOutlinedIcon />}</ListItemIcon>
                <ListItemText primary={getMessage('nav.editContent')} />
              </ListItem>
            </AccordionSummary>
            <AccordionDetails>
              {isCourseAdmin(user) &&
                activeModules?.includes('Course') &&
                (activeSubModules?.Course?.includes('PublicCourse') ||
                  activeSubModules?.Course?.includes('PrivateCourse')) &&
                courseAdminNavItems.map((item, index) => (
                  <AccordionNavListItem
                    key={index}
                    text={item.text}
                    icon={item.icon}
                    selected={item.selected}
                    url={item.url}
                    onClick={isMobile ? onDrawerToggle : undefined}
                  />
                ))}
              {isForumAdmin(user) && activeModules?.includes('Forum')
                ? forumAdminNavItems.map((item, index) => (
                    <AccordionNavListItem
                      key={index}
                      text={item.text}
                      icon={item.icon}
                      selected={item.selected}
                      url={item.url}
                      onClick={isMobile ? onDrawerToggle : undefined}
                    />
                  ))
                : null}
              {isAdmin(user)
                ? homeAdminNavItems.map((item, index) => (
                    <AccordionNavListItem
                      key={index}
                      text={item.text}
                      icon={item.icon}
                      selected={item.selected}
                      url={item.url}
                      onClick={isMobile ? onDrawerToggle : undefined}
                    />
                  ))
                : null}
            </AccordionDetails>
          </Accordion>
          {isAdmin(user) && (
            <Accordion
              expanded={expandedSettings}
              onChange={() => {
                if (!expandedSettings) {
                  setExpandedEditContent(false);
                  setExpandedStatistics(false);
                }
                setExpandedSettings(!expandedSettings);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="admin-Settings"
                id="admin-settings"
              >
                <ListItem>
                  <ListItemIcon>{<SettingsOutlinedIcon />}</ListItemIcon>
                  <ListItemText primary={getMessage('nav.editSettings')} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                {adminSettingsNavItems.map((item, index) => (
                  <AccordionNavListItem
                    key={index}
                    text={item.text}
                    icon={item.icon}
                    selected={item.selected}
                    url={item.url}
                    onClick={isMobile ? onDrawerToggle : undefined}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}
          {isAdmin(user) &&
              usersAdminNavItems.map((item, index) => (
                <NavListItem
                  key={index}
                  text={item.text}
                  icon={item.icon}
                  selected={item.selected}
                  url={item.url}
                  onClick={isMobile ? onItemClick : undefined}
                />
              ))}

          {(isCourseAdmin(user) || isAdmin(user)) && activeModules?.includes('Course') && activeSubModules?.Course.includes('ExamCourse') && (
            [...[], courseAdminNavItem].map((item, index) => (
              <NavListItem
                key={index}
                text={item.text}
                icon={item.icon}
                selected={item.selected}
                url={item.url}
                onClick={isMobile ? onItemClick : undefined}
              />
              )
            ))}
          
          {isAdmin(user) && !!actors && (
            <Accordion
              expanded={expandedStatistics}
              onChange={() => {
                if (!expandedStatistics) {
                  setExpandedEditContent(false);
                  setExpandedSettings(false);
                }
                setExpandedStatistics(!expandedStatistics);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="statistics"
                id="statistics"
              >
                <ListItem className={classes.navListItem}>
                  <ListItemIcon>
                    <ShowChartIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={getMessage('nav.statistics.statistics')}
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                {statisticsNavItems.map(
                  (item, index) =>
                    (item.moduleActive ||
                      !item.hasOwnProperty('moduleActive')) && (
                      <AccordionNavListItem
                        key={index}
                        text={item.text}
                        icon={item.icon}
                        selected={item.selected}
                        url={item.url}
                        onClick={isMobile ? onDrawerToggle : undefined}
                      />
                    )
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {isAdmin(user) &&
            !actors &&
            statisticsNavItems.map(
              (item, index) =>
                (item.moduleActive || !item.hasOwnProperty('moduleActive')) && (
                  <NavListItem
                    key={index}
                    text={item.text}
                    icon={item.icon}
                    selected={item.selected}
                    url={item.url}
                    onClick={isMobile ? onItemClick : undefined}
                  />
                )
            )}
        </Box>
      )}
      {user && !isAdmin(user) && canAssignActorIds.length > 0 && (
        <Box className={classes.adminSection}>
          <InputLabel id="admin-items-label" className={classes.adminLabel}>
            <Typography variant="overline">
              <Message id="nav.adminLabel" />
            </Typography>
          </InputLabel>
          <NavListItem
            key={actorAdminNavItem.url}
            text={actorAdminNavItem.text}
            icon={actorAdminNavItem.icon}
            selected={actorAdminNavItem.selected}
            url={actorAdminNavItem.url}
            onClick={isMobile ? onItemClick : undefined}
          />
        </Box>
      )}
    </List>
  );
};

export default DrawerList;

type NavItemProps = {
  text: ReactNode;
  icon: ReactNode;
  selected: boolean;
  url: string;
  moduleActive?: boolean;
  color?: string;
  onClick?: () => void;
};

const NavListItem = ({
  text,
  icon,
  selected,
  url,
  color,
  onClick,
}: NavItemProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userArea = useSelector(userAreaSelector);
  const currentGroup = useSelector(currentGroupSelector);
  const onItemClick = () => {
    if (userArea) {
      dispatch(setUserArea(null));
    } else if (currentGroup) {
      dispatch(setCurrentGroup(null));
    }
    if (onClick !== undefined) {
      onClick();
    }
  };

  let tinyColor = require('tinycolor2');
  const thingColor = !color ? theme.palette.primary.main : color;
  const bgColor = !color
    ? theme.palette.secondary.main
    : tinyColor(color).lighten(30);

  return (
    <ListItem
      button
      component={Link}
      to={url}
      selected={selected}
      className={classes.navListItem}
      style={selected ? { backgroundColor: bgColor } : {}}
      onClick={onItemClick}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
      {selected && (
        <div
          className={classes.navMarker}
          style={{ backgroundColor: thingColor }}
        ></div>
      )}
    </ListItem>
  );
};

const AccordionNavListItem = ({
  text,
  icon,
  selected,
  url,
  onClick,
}: NavItemProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.accordionNavItem}>
      <NavListItem
        text={text}
        icon={icon}
        selected={selected}
        url={url}
        onClick={onClick}
      />
    </Box>
  );
};
